import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import nayeonHead320 from '../img/doodles/nayeon_head_320w.webp';
import nayeonHead640 from '../img/doodles/nayeon_head_640w.webp';
import nayeonHead960 from '../img/doodles/nayeon_head_960w.webp';
import nayeonHead1280 from '../img/doodles/nayeon_head_1280w.webp';
import catHead320 from '../img/doodles/cat_head_320w.webp';
import catHead640 from '../img/doodles/cat_head_640w.webp';
import catHead960 from '../img/doodles/cat_head_960w.webp';
import catHead1024 from '../img/doodles/cat_head_1024w.webp';
import spencerHead320 from '../img/doodles/spencer_head_320w.webp';
import spencerHead640 from '../img/doodles/spencer_head_640w.webp';
import spencerHead960 from '../img/doodles/spencer_head_960w.webp';
import spencerHead1280 from '../img/doodles/spencer_head_1280w.webp';
import nayeon320 from '../img/doodles/nayeon_self_portrait_320w.webp';
import nayeon640 from '../img/doodles/nayeon_self_portrait_640w.webp';
import nayeon960 from '../img/doodles/nayeon_self_portrait_960w.webp';
import nayeon1280 from '../img/doodles/nayeon_self_portrait_1280w.webp';
import spencer320 from '../img/doodles/spencer_portrait_small_320w.webp';
import spencer640 from '../img/doodles/spencer_portrait_small_640w.webp';
import gear320 from '../img/doodles/gear_5_bria_centered_320w.webp';
import gear640 from '../img/doodles/gear_5_bria_centered_640w.webp';
import gear806 from '../img/doodles/gear_5_bria_centered_806w.webp';
import { ArrowElbowRightDown } from '@phosphor-icons/react';


const spin = keyframes`
  0% { transform: rotate(0deg); }
  40% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
`;

const Background = styled.div`
    width: 100%;
    background: #fff9ef;
`;

const BigContainer = styled.div`
    padding-top: 2.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 16rem;
    width: 90%;
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 67%;
    font-size: 26px;
    margin-bottom: 3rem;
    color: #222;
    margin-right: auto;
    margin-left: auto;
    letter-spacing: .07em;
    @media (max-width: 600px) {
        font-size: 20px;
        width: 100%;
    }
`;

const Button = styled.button`
  width: 320px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 6rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 275px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  letter-spacing: 0.04em;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`;

const HeadsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -3vw;
    margin-right: -3vw;
`;

const SingleHead = styled.div`
    width: 42%;
    height: auto;
    margin: 0 -4.5%;
    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
`;

const MessageBold = styled.div`
    display: inline;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    color: #222;
    letter-spacing: .07em;
    @media (max-width: 600px) {
        font-size: 20px;
        width: 90%;
    }
`;

const MessageBoldUnderline = styled.div`
    display: inline;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    color: #222;
    letter-spacing: .07em;
    text-decoration: underline;
    text-decoration-thickness: 3px;
    cursor: pointer;
    @media (max-width: 600px) {
        font-size: 20px;
        width: 90%;
    }
`;

const SpecialUnderlinedWord = styled.span`
    text-decoration: underline;
    text-decoration-thickness: 3px;
    -webkit-text-decoration-thickness: 3px;
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip-ink: none;
`;

const TitleContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;
    @media (max-width: 600px) {
      margin-bottom: 2rem;
    }
`;

const SVGTitleOne = styled.svg`
    width: 100%;
    height: auto;
    margin: 0;
    text {
        font-family: 'NewKansas';
        fill: #222;
        line-height: 0.9;
    }
`;

const SVGTitleTwo = styled.svg`
    width: 100%;
    height: auto;
    margin: 0;
    text {
        font-family: 'NewKansas';
        fill: #222;
        line-height: 0.9;
    }
    margin-bottom: 18rem;
    @media (max-width: 1000px) {
        margin-bottom: 12rem;
    }
    @media (max-width: 600px) {
        margin-bottom: 6rem;
    }
    margin-top: -3.5vw;
`;

const PortraitsContainer = styled.div`
    width: 80%;
    @media (max-width: 600px) {
        width: 100%;
        gap: 4rem;
    }
    display: flex;
    gap: 8rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
    margin-top: 18rem;
    @media (max-width: 1000px) {
        margin-top: 12rem;
    }
    @media (max-width: 600px) {
        margin-top: 6rem;
    }
    margin-bottom: 18rem;
    @media (max-width: 1000px) {
        margin-bottom: 12rem;
    }
    @media (max-width: 600px) {
        margin-bottom: 6rem;
    }
`;

const PortraitsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-bottom: 4rem;
    justify-content: center;
    width: 100%;
    @media (max-width: 600px) {
        gap: 12px;
    }
`;

const NayeonContainer = styled.div`
    width: 48%;
    aspect-ratio: 4/5;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        left: 8px;
    }
`;

const SpencerContainer = styled.div`
    width: 48%;
    aspect-ratio: 4/5;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const PortraitBioContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${props => props.align || 'flex-start'};
    padding-top: 2rem;
`;

const PortraitTitle = styled.div`
    font-family: 'NewKansas';
    font-size: clamp(20px, 7.2vw, 70px);
    color: #222;
    letter-spacing: 0.04em;
    @media (max-width: 800px) {
        font-size: clamp(20px, 8.4vw, 70px);
    }
`;

const PortraitInfoStartAligned = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    line-height: 1.6;
    color: #222;
    letter-spacing: 0.07em;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    max-width: 475px;

    span {
        position: relative;
        &:not(:first-child)::before {
            content: "•";
            position: absolute;
            left: -0.75em;
        }
    }

    @media (max-width: 800px) {
        font-size: 20px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

const PortraitInfoEndAligned = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    line-height: 1.6;
    color: #222;
    letter-spacing: 0.07em;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
    max-width: 475px;

    span {
        position: relative;
        &:not(:last-child)::after {
            content: "•";
            position: absolute;
            right: -0.75em;
        }
    }

    @media (max-width: 800px) {
        font-size: 20px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

const GearBox = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3vw;
`;

const GearImage = styled.img`
    width: 32%;
    object-fit: contain;
    transform: rotate(${props => props.startRotation || 0}deg);
    animation: ${spin} 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    animation-delay: ${props => props.delay || 0}s;
    transform-origin: center;

    ${props => css`
        animation: ${keyframes`
            from { transform: rotate(${props.startRotation || 0}deg); }
            40% { transform: rotate(${(props.startRotation || 0) + 360}deg); }
            to { transform: rotate(${(props.startRotation || 0) + 360}deg); }
        `} 8s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    `}

    &:nth-child(2) {
        animation-direction: reverse;
    }
`;

const ExplanationSection = styled.div`
    width: 100%;
`;

const SectionTitle = styled.div`
    font-family: 'NewKansas';
    font-size: clamp(28px, 9.1vw, 500px);
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 6rem;
    color: #222222;
    white-space: nowrap;
    
    @media (max-width: 600px) {
        margin-bottom: 4rem;
    }
`;

const SVGSectionTitle = styled.svg`
    width: 100%;
    height: auto;
    margin: 0;
    text {
        font-family: 'NewKansas';
        fill: #222;
        line-height: 0.9;
    }
    margin-bottom: 6rem;
    margin-top: 2rem;
    @media (max-width: 600px) {
        margin-bottom: 4rem;
    }
`;


const About = () => {
    const [animate, setAnimate] = useState(false);
    const nayeonRef = useRef(null);

    useEffect(() => {
        document.title = 'Doodlebug | About'
      }, []);

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    const handleGoToCustomize = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    };

    const scrollToNayeon = () => {
        nayeonRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    return (
        <>
            <Background>
                <BigContainer animate = {animate}>
                    <HeadsContainer>
                        <SingleHead>
                            <img srcset={`${nayeonHead320} 320w, ${nayeonHead640} 640w, ${nayeonHead960} 960w, ${nayeonHead1280} 1280w`} sizes="42vw"src={nayeonHead1280} alt={""}/>
                        </SingleHead>
                        <SingleHead>
                            <img srcset={`${catHead320} 320w, ${catHead640} 640w, ${catHead960} 960w, ${catHead1024} 1024w`} sizes="42vw" src={catHead1024} alt={""}/>
                        </SingleHead>
                        <SingleHead>
                            <img srcset={`${spencerHead320} 320w, ${spencerHead640} 640w, ${spencerHead960} 960w, ${spencerHead1280} 1280w`} sizes="42vw" src={spencerHead1280} alt={""}/>
                        </SingleHead>
                    </HeadsContainer>
                    <TitleContainer>
                        <SVGTitleOne viewBox="0 0 120 20" preserveAspectRatio="xMidYMid meet">
                            <text x="60" y="15" textAnchor="middle">The Doodlebug</text>
                        </SVGTitleOne>
                        <SVGTitleTwo viewBox="0 0 77 20" preserveAspectRatio="xMidYMid meet">
                            <text x="38.5" y="15" textAnchor="middle">Company</text>
                        </SVGTitleTwo>
                    </TitleContainer>
                    <Message>We are an innovative <MessageBold>two person band</MessageBold> at the bleeding-edge intersection of weebs, hello kitty, squircles, and *AI* (ohmygosh).</Message>
                    <Message>On a noble mission to feed our cuteness-starved world with personalized, super-cute doodlebug companions, and never neglecting to feed our talented <MessageBoldUnderline onClick={scrollToNayeon}>human <span style={{ whiteSpace: 'nowrap', display: 'inline-flex', gap: '8px', justifyContent: 'center', alignItems: 'center' }}><SpecialUnderlinedWord>artist</SpecialUnderlinedWord><ArrowElbowRightDown size={30} weight="bold" color='#222222'/></span></MessageBoldUnderline> in the process.</Message>
                    <PortraitsContainer ref={nayeonRef}>
                        <PortraitsRow>
                            <NayeonContainer>
                                <svg width="100%" height="100%">
                                    <defs>
                                        <pattern id="angledDots" patternUnits="userSpaceOnUse" width="11" height="11" patternTransform="rotate(256)">
                                            <circle cx="4" cy="4" r="4" fill="#fcc7c1" />
                                        </pattern>
                                    </defs>
                                    <rect width="100%" height="100%" fill="url(#angledDots)" />
                                </svg>
                                <img
                                    srcSet={`
                                        ${nayeon320} 320w,
                                        ${nayeon640} 640w,
                                        ${nayeon960} 960w,
                                        ${nayeon1280} 1280w
                                    `}
                                    sizes="(max-width: 600px) 48vw, 40vw"
                                    src={nayeon1280}
                                    alt="">
                                </img>
                            </NayeonContainer>
                            <PortraitBioContainer>
                                <PortraitTitle>Nayeon</PortraitTitle>
                                <PortraitInfoStartAligned>
                                    <span>artist</span>
                                    <span>animator</span>
                                    <span>Korea</span>
                                    <span>cookies</span>
                                    <span>k-dramas</span>
                                    <span>INTP</span>
                                </PortraitInfoStartAligned>
                            </PortraitBioContainer>
                        </PortraitsRow>
                        <PortraitsRow>
                            <PortraitBioContainer align="flex-end">
                                <PortraitTitle>Spencer</PortraitTitle>
                                <PortraitInfoEndAligned align="flex-end">
                                    <span>technologist</span>
                                    <span>USA</span>
                                    <span>j-pop</span>
                                    <span>books</span>
                                    <span>chocolate</span>
                                    <span>ENTJ</span>
                                </PortraitInfoEndAligned>
                            </PortraitBioContainer>
                            <SpencerContainer>
                                <svg width="100%" height="100%">
                                    <defs>
                                        <pattern id="angledDotsBlue" patternUnits="userSpaceOnUse" width="11" height="11" patternTransform="rotate(201)">
                                            <circle cx="4" cy="4" r="4" fill="#9ddcfe" />
                                        </pattern>
                                    </defs>
                                    <rect width="100%" height="100%" fill="url(#angledDotsBlue)" />
                                </svg>
                                <img
                                    srcSet={`
                                        ${spencer320} 320w,
                                        ${spencer640} 640w
                                    `}
                                    sizes="(max-width: 600px) 48vw, 40vw"
                                    src={spencer640}
                                    alt="">
                                </img>
                            </SpencerContainer>
                        </PortraitsRow>
                    </PortraitsContainer>

                    <GearBox>
                        <GearImage srcset={`${gear320} 320w, ${gear640} 640w, ${gear806} 806w`} sizes="32vw" src={gear806} alt="" startRotation={0} delay={0}/>
                        <GearImage srcset={`${gear320} 320w, ${gear640} 640w, ${gear806} 806w`} sizes="32vw" src={gear806} alt="" startRotation={80} delay={1}/>
                        <GearImage srcset={`${gear320} 320w, ${gear640} 640w, ${gear806} 806w`} sizes="32vw" src={gear806} alt="" startRotation={125} delay={4}/>
                    </GearBox>

                    <ExplanationSection>
                        <SVGSectionTitle viewBox="0 0 145 20" preserveAspectRatio="xMidYMid meet">
                            <text x="72.5" y="15" textAnchor="middle">Machines at work!</text>
                        </SVGSectionTitle>
                        <Message>We use AI to create personalized doodlebugs for you. Image generation AI works by studying many images and learning their patterns. It can even imitate the styles of talented artists. But many people who use AI don't credit the original artists—much less compensate them!</Message>
                        <Message><MessageBold>Doodlebug is different.</MessageBold> Our AI draws doodlebugs in Nayeon's style. And because her style is used, Nayeon is paid for every doodlebug shipped.</Message>
                    </ExplanationSection>

                    <LinkToHome to="/customize" onClick={handleGoToCustomize}>
                        <Button><ButtonText>Create a Doodlebug</ButtonText></Button>
                    </LinkToHome>
                </BigContainer>
            </Background>
        </>
    )
}

export default About;
