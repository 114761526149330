import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Bookmarks, BookOpenText, DeviceMobile, GearSix, House, List, PawPrint, ShoppingCartSimple, SignIn, SignOut, X } from '@phosphor-icons/react';
import { Badge } from '@mui/material';
import UserInfoContext from '../contexts/UserInfoContext';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import ContinuedWithoutSavingDoodlebugsContext from '../contexts/ContinuedWithoutSavingDoodlebugsContext';
import ItemsContext from '../contexts/ItemsContext';
import Cookies from 'js-cookie';
import { AnimatePresence, motion } from 'framer-motion';


const Container = styled.div`
    display: ${props => props.location === '/new-review' ? 'none' : 'block'};
    height: 60px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 4rem;
    padding-left: 4rem;
    @media (max-width: 600px) {
        width: 100%;
        padding-right: 2rem;
        padding-left: 2rem;
    }
    background: #fff9ef;
    box-sizing: border-box;
`;

const Wrapper = styled.div`
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    transition: top 0.2s ease-in-out;
    z-index: 500;
    background: #fff9ef;
`;

const Left = styled.div`
    flex: 1;
    text-align: left;
    font-family: 'Righteous';
    font-size: 2rem;
    padding: 0;
    @media (max-width: 600px) {
        font-size: 1.3rem;
    }
`;

const HomeText = styled.span`
    font-family: 'NewKansas';
    font-size: 2rem;
    color: black;
    &:hover {
        transform: scale(1.03);
        transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(.99);
        transition: all 0.05s ease-in-out;
    }
    @media (max-width: 600px) {
        font-size: 1.3rem;
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
        }
    }
`

const Center = styled.div`
    flex: 1;
    text-align: center;
`;

const Right = styled.div`
    flex: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    gap: .4rem;
`;

const Button = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;
`

const HomeButton = styled.button`
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        transform: scale(1.05);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    &:active {
        transform: scale(.99);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    @media (max-width: 600px) {
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
    }
`;

const MenuContainer = styled(motion.div)`
    position: absolute;
    top: 60px;
    right: 2rem;
    width: ${({ width }) => width || '250px'};
    background-color: #ffffff;
    border-radius: 18px;
    border: 4px solid #d5d4c3;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const UserInfo = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.2rem;
    margin-bottom: 8px;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const UserName = styled.span`
  font-family: NewKansas;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 186px;
`;

const UserEmail = styled.span`
  font-size: 0.9em;
  color: #666;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 186px;
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  gap: 1rem;
  padding: 6px;
  color: #222222;
  &:hover {
    background-color: #d5d4c3;
  }
`;

const ButtonText = styled.span`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #222222;
`;

const Divider = styled.hr`
  border: none;
  border-top: 2px solid #d5d4c3;
  width: 100%;
`;

const LoginOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const LoginContainer = styled(motion.div)`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
`;

const LoginTitle = styled.h2`
  font-family: 'NewKansas', sans-serif;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const LoginButton = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  background: #d5d4c3;
  color: #222222;
  border: none;
  border-radius: 18px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #d5d4c3;
  }
  transition: background-color 0.2s;  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    background-color: #fe814d;
    color: #222;
    font-weight: bold;
  }
`;

const SVGContainer = styled.div`
  height: 1.5em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    height: 100%;
    width: auto;
  }
`;

const IconHeaderContainer = styled.div`
  border: none;
  border-radius: 50%;
  background: #fcc7c1;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;


const Navbar = ({ openCart }) => {
    const { items, setItems } = useContext(ItemsContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
    const { continuedWithoutSavingDoodlebugs, setContinuedWithoutSavingDoodlebugs } = useContext(ContinuedWithoutSavingDoodlebugsContext);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const menuRef = useRef(null);

    // to close the menu when we click outside of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCartClick = () => {
        setMenuIsOpen(false);
        openCart();
    };

    const handleHomeClick = () => {
        setMenuIsOpen(false);
        navigate('/');
    };

    const handleShopPhoneCaseClick = () => {
      setMenuIsOpen(false);
      if (savedDoodlebugs.length > 0 || continuedWithoutSavingDoodlebugs.length > 0) {
        navigate('/order/phone-case');
      } else {
        navigate('/customize');
      }
    };

    const handleCustomizeClick = () => {
      setMenuIsOpen(false);
      navigate('customize');
    };

    const handleAboutClick = () => {
      setMenuIsOpen(false);
      navigate('about');
    };

    const handleMyDoodlebugsClick = () => {
      setMenuIsOpen(false);
      navigate('my-doodlebugs');
    }

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };

    const logout = () => {
        setMenuIsOpen(false);
        const logoutUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/logout' : 'http://localhost:3001/logout';
        fetch(logoutUrl, {
          method: 'GET',
          credentials: 'include'
        })
        .then(response => {
            if (response.ok) {
                setUserInfo({status: "unauthenticated"});
                setSavedDoodlebugs([]);
                setItems([]);
                navigate("/");
            } else {
                console.error('Logout failed');
            }
        })
        .catch(error => {
            console.error('Error during logout:', error);
        });
    };

    const openLogin = () => {
        setMenuIsOpen(false);
        setShowLoginPopup(true);
    };

    const closeLogin = () => {
        setShowLoginPopup(false);
    };

    const googleLogin = async () => {
        const loginUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/prepare-google-auth' : 'http://localhost:3001/prepare-google-auth';
        const requestBody = continuedWithoutSavingDoodlebugs.length > 0 ? JSON.stringify({ destination: '', items: items, urls: continuedWithoutSavingDoodlebugs }) : JSON.stringify({ destination: '', items: items });
        console.log(requestBody);
        const response = await fetch(loginUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:  requestBody,
            credentials: 'include'
        });
        const data = await response.json();
        window.location.href = data.authUrl;
    };

    const navigateAccountOptions = () => {
        setMenuIsOpen(false);
    };

    return (
        <Container location={location.pathname}>
            <Wrapper>
                <Left>
                    <HomeButton onClick = {handleHomeClick}>
                        <HomeText>Doodlebug</HomeText>
                    </HomeButton>
                </Left>
                <Center></Center>
                <Right>
                    <Button onClick = {handleCartClick}>
                        <IconWrapper>
                            <StyledBadge badgeContent={(items.length > 0) && items[0] !== "getting" ? items.length : null} color="">
                                <ShoppingCartSimple size={32} color="#222222"/>
                            </StyledBadge>
                        </IconWrapper>
                    </Button>
                    <Button onClick = {toggleMenu}>
                        <IconWrapper>
                            {menuIsOpen ? (
                                <X size={32} color="#222222" />
                            ): (
                                <List size={32} color="#222222" />
                            )}
                        </IconWrapper>
                    </Button>
                    {(menuIsOpen && userInfo.status==="authenticated") ? (
                        <MenuContainer ref={menuRef} width="250px"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.1 }}
                        >
                            <UserInfo>
                              <UserImage src={userInfo.pfp} alt="Profile" />
                              <UserDetails>
                                <UserName>{userInfo.name}</UserName>
                                <UserEmail>{userInfo.email}</UserEmail>
                              </UserDetails>
                            </UserInfo>
                            <MenuButton onClick={handleHomeClick}>
                              <House size={24} color="#222222" />
                              <ButtonText>Home</ButtonText>
                            </MenuButton>
                            <MenuButton onClick={handleMyDoodlebugsClick}>
                              <Bookmarks size={24} color='#222222' />
                              <ButtonText>My Doodlebugs</ButtonText>
                            </MenuButton>
                            <MenuButton onClick={handleCustomizeClick}>
                              <PawPrint size={24} color='#222222' />
                              <ButtonText>Create Doodlebug</ButtonText>
                            </MenuButton>
                            {(savedDoodlebugs.length > 0 || continuedWithoutSavingDoodlebugs.length > 0) && (
                              <MenuButton onClick={handleShopPhoneCaseClick}>
                                <DeviceMobile size={24} color='#222222' />
                                <ButtonText>Order Now</ButtonText>
                              </MenuButton>
                            )}
                            <Divider />
                            <MenuButton onClick={logout}>
                              <SignOut size={24} color="#222222" />
                              <ButtonText>Log out</ButtonText>
                            </MenuButton>
                        </MenuContainer>
                    ) : menuIsOpen && (
                        <MenuContainer ref={menuRef} width="240px"
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.1 }}
                        >
                            <MenuButton onClick={handleHomeClick}>
                              <House size={24} color="#222222" />
                              <ButtonText>Home</ButtonText>
                            </MenuButton>
                            <MenuButton onClick={handleCustomizeClick}>
                              <PawPrint size={24} color='#222222' />
                              <ButtonText>Create Doodlebug</ButtonText>
                            </MenuButton>
                            {(savedDoodlebugs.length > 0 || continuedWithoutSavingDoodlebugs.length > 0) && (
                              <MenuButton onClick={handleShopPhoneCaseClick}>
                                <DeviceMobile size={24} color='#222222' />
                                <ButtonText>Order Now</ButtonText>
                              </MenuButton>
                            )}
                            <MenuButton onClick={handleAboutClick}>
                              <BookOpenText size={24} color='#222222' />
                              <ButtonText>About Us</ButtonText>
                            </MenuButton>
                            <MenuButton onClick={openLogin}>
                              <SignIn size={24} color="#222222" />
                              <ButtonText>Sign In</ButtonText>
                            </MenuButton>
                        </MenuContainer>
                    )}
                </Right>
            </Wrapper>
            <AnimatePresence>
              {showLoginPopup && (
                <LoginOverlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={closeLogin}
                >
                  <LoginContainer
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconHeaderContainer>
                        <SignIn size={36} color="#222222" />
                    </IconHeaderContainer>
                    <LoginTitle>Sign-in Options</LoginTitle>
                    <LoginButton onClick={googleLogin}>
                        <SVGContainer>
                            <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-1693 -927)">
                                    <circle transform="translate(1694 928)" cx="16" cy="16" r="16" fill="#fff"></circle>
                                    <path d="M1717.68 944.182a9.192 9.192 0 0 0-.145-1.636H1710v3.095h4.305a3.68 3.68 0 0 1-1.6 2.415v2.006h2.585a7.8 7.8 0 0 0 2.39-5.88z" fill="#4285f4" fillRule="evenodd"></path>
                                    <path d="M1710 952a7.638 7.638 0 0 0 5.294-1.938l-2.585-2.007a4.826 4.826 0 0 1-7.185-2.535h-2.673v2.073A8 8 0 0 0 1710 952z" fill="#34a853" fillRule="evenodd"></path>
                                    <path d="M1705.5 945.52a4.73 4.73 0 0 1 0-3.04v-2.073h-2.673a8.01 8.01 0 0 0 0 7.185l2.673-2.073z" fill="#fbbc05" fillRule="evenodd"></path>
                                    <path d="M1710 939.182a4.322 4.322 0 0 1 3.058 1.2l2.295-2.295A7.688 7.688 0 0 0 1710 936a8 8 0 0 0-7.149 4.407l2.673 2.073a4.768 4.768 0 0 1 4.476-3.3z" fill="#ea4335" fillRule="evenodd"></path>
                                </g>
                            </svg>
                        </SVGContainer>
                        Sign in with Google
                    </LoginButton>
                    <CloseButton onClick={closeLogin}>
                        <X size={24} color="#222222"/>
                    </CloseButton>
                  </LoginContainer>
                </LoginOverlay>
              )}
            </AnimatePresence>
        </Container>
    )
}

export default Navbar;