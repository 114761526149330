import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import '../css/fonts.css';
import { ArrowBendRightDown, ArrowRight } from '@phosphor-icons/react';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import UserInfoContext from '../contexts/UserInfoContext';
import hero_1200 from '../img/landing_images/hero_case_1200.webp';
import hero_full from '../img/landing_images/hero_case_full.webp';
import dog_lover from '../img/vibe_images/dog_lover.jpg';
import cat_lover from '../img/vibe_images/cat_lover.jpg';
import girl_boss from '../img/vibe_images/girl_boss.jpg';
import sweet_tooth from '../img/vibe_images/sweet_tooth.jpg';
import NayeonImage from '../img/nayeon.webp';
import green from '../img/vibe_images/green.jpg';
import dreamer from '../img/vibe_images/dreamer.jpg';
import hero_with_backdrop from '../img/landing_images/hero_case_with_backdrop.png';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
`;

const goAway = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .2;
    transform: scale(.95);
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  background: #fff9ef;
`;

const HeroBlock = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 2rem;
  }
  padding-bottom: 15rem;
`

const Title = styled.h1`
  font-family: 'NewKansas';
  color: #222;
  font-size: 46px;
  margin-bottom: 4rem;
  box-sizing: border-box;
  text-align: left;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
  @media (max-width: 800px) {
    font-size: 32px;
    margin-bottom: 2rem;
  }
  @media (max-width: 470px) {
    font-size: 30px;
    margin-bottom: 2rem;
  }
`;

const Text1 = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 22px;
    margin-bottom: 0rem;
  }
`;

const Text2 = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  padding-left: 10px;
  font-size: 17px;
  @media (max-width: 600px) {
    font-size: 14px;
    margin-bottom: 0rem;
  }
`;

const ContainerForAllHeroElements = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
  gap: 3rem;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 0;
    gap: 0;
  }
`;

const ContainerForTitleAndButtons = styled.div`
  @media (min-width: 801px) {
    flex: 4;
  }
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media (max-width: 800px) {
    margin-top: 0;
  }
`;

const HeroImageContainer = styled.div`
  @media (min-width: 801px) {
    flex: 6;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-height: 800px;
  position: relative;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    min-width: 0;
  }
  @media (max-width: 800px) {
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    height: 100vw;
    min-width: 0;
    margin-top: 0;
  }
  & > img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
`;

const SVGContainer = styled.div`
  z-index: 3;
  position: absolute;
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.4));
  left: 50%;
  top: 32%;
`

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

const CarouselImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  ${ImageContainer}:hover & {
    transition: all 0.05s ease-in-out;
  }
`

const ButtonContainerForAnimation = styled.div`
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.7s;
`

const CreateButton = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const TrendingImagesButton = styled.button`
width: 180px;
border: none;
height: 52px;
padding: 10px 20px;
color: #fff9ef;
background: #888;
border-radius: 18px;
font-weight: bold;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 2rem;
transform: scale(1);
&::after {
  content: '';
  position: absolute;
  border-radius: 78% / 5%;
  background-color: #888;
  top: -1px;
  bottom: -1px;
  right: 18px;
  left: 18px;
  z-index: -1;
}
&:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
}
&:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
}
@media (max-width: 600px) {
  font-size: 14px;
  max-width: 250px;
}
`;

const BlurbBlock = styled.div`
  width: 100%;

`;

const BlurbContainer = styled.div`

`;

const MyDoodlebugsBlock = styled.div`
  width: 100%;
  padding: 2rem;
  background: #d5d4c3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10rem;
`;

const MyDoodlebugsPreviewContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  @media (max-width: 800px) {
    width: 88%;
  }
`;

const MyDoodlebugsScroller = styled.div`
  display: flex;
  gap: 12px;
  overflow-x: auto;
  scrollbar-width: none;
  position: relative;
  padding-right: 50px;
  width: 100%;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  mask-image: linear-gradient(
    to right,
    black calc(100% - 100px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    black calc(100% - 100px),
    transparent 100%
  );
`;

const DoodlebugPreview = styled.img`
  width: 158px;
  height: 180px;
  border-radius: 12px;
  border: 4px solid #fff9ef;
  object-fit: cover;
  flex-shrink: 0;
`;

const MyDoodlebugsHeader = styled.div`
  font-family: 'NewKansas';
  color: #222;
  font-size: 32px;
`;

const SeeMyDoodlebugsButton = styled.button`
  padding: 0;
  border: none;
  background: none;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const SeeMyDoodlebugsButtonText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #444;
  cursor: pointer;
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const SeeTheRestButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  height: 180px;
  min-width: 200px;
  padding-left: 1.5rem;
`;

const UniqueVibesContainer = styled.div`
  width: 75%;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 6rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding-bottom: 10rem;
  @media(max-width: 600px) {
    width: 88%;
  }
  @media (max-width: 800px) {
    width: 88%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
`;

const UniqueVibesLeftColumn = styled.div`
  order: 1;
  flex: 1;
  @media (max-width: 800px) {
    order: 2;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UniqueVibesRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  order: 2;
  @media (max-width: 800px) {
    order: 1;
  }
`;

const UniqueVibesHeader = styled.div`
  font-family: 'NewKansas';
  color: #222;
  font-size: 36px;
  margin-bottom: 2rem;
  text-align: center;
  @media (max-width: 490px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    &::after {
      content: 'doodlebug for:';
      display: block;
    }
    &::before {
      content: 'Create a';
      display: block;
    }
    font-size: 32px;
  }
`;

const UniqueVibesOptionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
  width: 100%;
  max-width: 700px;
  margin-bottom: 2rem;
  @media (max-width: 600px) {
    gap: 28px;
  }
`;

const UniqueVibesOption = styled.button`
  border: none;
  padding: 0;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  transform: rotate(${props => props.tilt}deg);
  transition: transform 0.2s ease-in-out;
  position: relative;
  padding: 8px 0px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  color: #222222;
  &:hover {
    transform: rotate(${props => props.tilt > 0 ? props.tilt - 4 : props.tilt + 4}deg);
  }
  @media (max-width: 600px) {
    gap: 28px;
  }
`;

const UniqueVibesText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 1.5rem;
`;

const BlobBackground = styled.div`
  position: absolute;
  width: 75%;
  height: 75%;
  background: #fcc7c1;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  z-index: -1;
  opacity: ${props => props.isSelected ? 1 : 0};
  transition: all 0.3s ease-in-out;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const UniqueVibesImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 4px solid #d5d4c3;
  width: 95%;
  margin-bottom: 2rem;
  max-height: 450px;
  max-width: 392px;
  background: #ffffff;
`;

const fadeTransition = css`
  opacity: ${props => props.visible ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`;

const UniqueVibesImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  ${fadeTransition}
`;

const ArtistHighlightSection = styled.div`
  padding-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10rem;
  
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 88%;
    padding-top: 2rem;
    padding-bottom: 5rem;
  }
`;

const ArtistHighlightContent = styled.div`
  padding: 1rem;
  
  @media (max-width: 800px) {
    order: 2;
  }
`;

const ArtistHighlightTitle = styled.h2`
  font-family: 'NewKansas', sans-serif;
  font-size: 36px;
  color: #222;
  margin-bottom: 1.5rem;
`;

const ArtistHighlightText = styled.p`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  line-height: 1.6;
  color: #222;
  margin-bottom: 1.5rem;
`;

const ArtistHighlightImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  @media (max-width: 800px) {
    order: 1;
  }
`;

const ArtistHighlightImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 18px;
  border: 10px solid #fcc7c1;
  box-sizing: border-box;
`;

const ArtistHighlightImageCaption = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #222;
  text-align: center;
`;



const Landing = () => {
  const navigate = useNavigate();
  const [clientWidth, setClientWidth] = useState(window.innerWidth);
  const [heroImage, setHeroImage] = useState(hero_1200);
  const [vibesImage, setVibesImage] = useState(dog_lover);
  const [selectedVibe, setSelectedVibe] = useState('dog_lover');
  const [isVisible, setIsVisible] = useState(true);
  const { savedDoodlebugs } = useContext(SavedDoodlebugsContext);
  const { userInfo } = useContext(UserInfoContext);

  // a function to load a single image into an image object
  const loadImage = async (src, useUniqueSrc) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        const uniqueSrc = `${src}?t=${new Date().getTime()}`;
        if (useUniqueSrc) {
            img.src = uniqueSrc;
        } else {
            img.src = src;
        }
        img.onload = () => resolve(img);
        img.onerror = (error) => {
            reject(error);
        };
    });
  };

  const loadHeroImage = async () => {
    const loadedImage = await loadImage(heroImage);
    setHeroImage(loadedImage);
  };

  const preloadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  const changeVibesImage = async (vibe, newVibesImage) => {
    if (vibesImage !== newVibesImage) {
      try {
        // First preload the new image
        await preloadImage(newVibesImage);
        
        // Once image is loaded, start the transition sequence
        setIsVisible(false);
        
        setTimeout(() => {
          setSelectedVibe(vibe);
          setVibesImage(newVibesImage);
          setIsVisible(true);
        }, 300);
      } catch (error) {
        console.error('Failed to load image:', error);
      }
    }
  };

  // loading our hero image so we don't have to continually make requests for the resource
  useEffect(() => {
    loadHeroImage();
  }, []);

  useEffect(() => {
    const adjustHeroImageForResize = () => {
      // const newHeroImage =
      //   document.documentElement.clientWidth <= 800 ? hero_800 :
      //   document.documentElement.clientWidth <= 1200 ? hero_1200 :
      //   document.documentElement.clientWidth <= 1600 ? hero_1600 :
      //   document.documentElement.clientWidth > 1600 ? hero_1920 :
      //   hero_800;
// 
      //   setHeroImage(newHeroImage);
    }

    const handleAllResizeEvents = () => {
      adjustHeroImageForResize();
      setClientWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleAllResizeEvents);

    return () => {
        window.removeEventListener('resize', handleAllResizeEvents);
    }
  }, []);

  useEffect(() => {
    document.title = 'Doodlebug | Cute friends to bring with you'
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  function navigateToCustomize() {
    navigate('/new-doodlebug');
  };

  function navigateToMyDoodlebugs() {
    navigate('/my-doodlebugs');
  }

  function navigateToQuiz() {
    navigate('/quiz');
  };

  return (
    <>
      <Wrapper>
        <HeroBlock>
          <ContainerForAllHeroElements>
            <ContainerForTitleAndButtons>
              <Title>Super cute friends to take with you!</Title>
              <ButtonContainerForAnimation><CreateButton onClick={() => navigateToCustomize()}><Text1>Make my own </Text1></CreateButton></ButtonContainerForAnimation>
              <ButtonContainerForAnimation><TrendingImagesButton><Text2>Quiz (soon!) </Text2></TrendingImagesButton></ButtonContainerForAnimation>
            </ContainerForTitleAndButtons>
            <HeroImageContainer>
              <img src={hero_with_backdrop}/>
              
            </HeroImageContainer>
          </ContainerForAllHeroElements>
        </HeroBlock>

        {userInfo.status === "authenticated" && savedDoodlebugs.length > 0 && (
          <MyDoodlebugsBlock>
            <MyDoodlebugsPreviewContainer>
              <MyDoodlebugsHeader>My doodlebugs</MyDoodlebugsHeader>
              <SeeMyDoodlebugsButton onClick={navigateToMyDoodlebugs}><SeeMyDoodlebugsButtonText>See all {savedDoodlebugs.length} </SeeMyDoodlebugsButtonText><ArrowRight weight="bold" size={20} color="#444444"/></SeeMyDoodlebugsButton>
              <MyDoodlebugsScroller>
                {savedDoodlebugs.slice(0, 5).map((url, index) => (
                  <DoodlebugPreview
                    key={`${url}-${index}`}
                    src={url} 
                    alt="Saved Doodlebug"
                  />
                ))}
                <SeeTheRestButtonContainer>
                  <SeeMyDoodlebugsButtonText  onClick={navigateToMyDoodlebugs}>+ {savedDoodlebugs.length - 5} more...</SeeMyDoodlebugsButtonText>
                </SeeTheRestButtonContainer>
              </MyDoodlebugsScroller>
            </MyDoodlebugsPreviewContainer>
          </MyDoodlebugsBlock>
        )}

        <ArtistHighlightSection>
          <ArtistHighlightContent>
            <ArtistHighlightTitle>From doodles to doodlebugs</ArtistHighlightTitle>
            <ArtistHighlightText>
              Our artist Nayeon drew the first doodlebugs. Doodlebug's AI used these to learn how to draw your ideas in her style.
            </ArtistHighlightText>
            <ArtistHighlightText>
              We believe in keeping humans in the creative loop, and Nayeon receives a piece of every sale.
            </ArtistHighlightText>
          </ArtistHighlightContent>
          <ArtistHighlightImageContainer>
            <ArtistHighlightImage 
              src={NayeonImage} 
              alt="Nayeon"
            />
            <ArtistHighlightImageCaption>Nayeon, in Doodlebug style</ArtistHighlightImageCaption>
          </ArtistHighlightImageContainer>
        </ArtistHighlightSection>

        <UniqueVibesContainer>
          <UniqueVibesRightColumn>
            <UniqueVibesHeader>
              {window.innerWidth > 490 ? 'Create a doodlebug for:' : ''}
            </UniqueVibesHeader>
            <UniqueVibesOptionsContainer>
              <UniqueVibesOption tilt={-12} onClick={() => changeVibesImage('dog_lover', dog_lover)}>
                dog lover
                <BlobBackground isSelected={selectedVibe === 'dog_lover'} />
              </UniqueVibesOption>
              <UniqueVibesOption tilt={10} onClick={() => changeVibesImage('cat_lover', cat_lover)}>
                cat lover
                <BlobBackground isSelected={selectedVibe === 'cat_lover'} />
              </UniqueVibesOption>
              <UniqueVibesOption tilt={6} onClick={() => changeVibesImage('girl_boss', girl_boss)}>
                girl boss
                <BlobBackground isSelected={selectedVibe === 'girl_boss'} />
              </UniqueVibesOption>
              <UniqueVibesOption tilt={-6} onClick={() => changeVibesImage('sweet_tooth', sweet_tooth)}>
                sweet tooth
                <BlobBackground isSelected={selectedVibe === 'sweet_tooth'} />  
              </UniqueVibesOption>
              <UniqueVibesOption tilt={-1} onClick={() => changeVibesImage('green', green)}>
                plant lover
                <BlobBackground isSelected={selectedVibe === 'green'} />  
              </UniqueVibesOption>
              <UniqueVibesOption tilt={-4} onClick={() => changeVibesImage('dreamer', dreamer)}>
                adventure
                <BlobBackground isSelected={selectedVibe === 'dreamer'} />  
              </UniqueVibesOption>
            </UniqueVibesOptionsContainer>
          </UniqueVibesRightColumn>
          <UniqueVibesLeftColumn>
            <UniqueVibesImageContainer>
              <UniqueVibesImage src={vibesImage} visible={isVisible}/>
            </UniqueVibesImageContainer>
            <CreateButton onClick={() => navigateToCustomize()}><Text1>Make my own</Text1></CreateButton>
          </UniqueVibesLeftColumn>
        </UniqueVibesContainer>

      </Wrapper>
    </>
  );
}

export default Landing;
