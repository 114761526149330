import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { css, keyframes } from 'styled-components';
import ItemsContext from '../contexts/ItemsContext';
import { CaretRight, Trash } from '@phosphor-icons/react';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import ContinuedWithoutSavingDoodlebugsContext from '../contexts/ContinuedWithoutSavingDoodlebugsContext';


const Background = styled.div`
  width: 100%;
  background: #fff9ef;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  height: 100%;
`;

const CartContainer = styled.div`
  padding-top: 2.5rem;  
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
`;

const CartBottom = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

const CartItemsContainer = styled.div`
  padding-bottom: 2rem;
  flex: 1;
  overflow-y: auto;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 26px;
  padding: 16px;
  margin-bottom: 16px;
  background: #ffe3bd;
`;

const ProductImageContainer = styled.div`
  border: 4px solid #d5d4c3;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 106px;
  @media (max-width: 600px) {
    height: 80px;
    width: 57px;
  }
  margin-right: 16px;
`;

const ProductImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const ItemDetails = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;  
  justify-content: flex-start;
`;

const ItemName = styled.h3`
  font-family: 'NewKansas';
  margin-bottom: 1.5rem;
  font-size:20px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

const QuantityBox = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ItemQuantity = styled.p`
  margin: 5px 0;
  font-family: 'Poppins', sans-serif;
`;

const QuantityButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: row;
  }
`;

const IncrementButton = styled.button`
  margin-left: .24rem;
  margin-right: .5rem;
  border-radius: 50%;
  height: 20px;
  font-weight: bold;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  border: 1px solid #000000;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 0px;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-right: 0;
    &:hover {
    transform: scale(1);
    transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(1);
        transition: all 0.05s ease-in-out;
    }
  }
`;

const DecrementButton = styled.button`
  margin-left: .5rem;
  margin-right: .24rem;
  border-radius: 50%;
  height: 20px;
  font-weight: bold;
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  border: 1px solid #000000;
  background: none;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  padding: 0px;
  &:hover {
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.95);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    &:hover {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
        transform: scale(1);
        transition: all 0.05s ease-in-out;
    }
  }
`;

const ItemPrice = styled.p`
  margin: 5px 0;
  font-family: 'Poppins', sans-serif;
`;

const IconWrapper = styled.div`
  &:hover {
    color: red;
    transform: scale(1.2);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    color: red;
    transform: scale(.9);
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    margin-right: 10px;
    &:hover {
      color: red;
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
      color: red;
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
`;

const EmptyText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 20px;
`;

const PrimaryButton = styled.button`
  width: 300px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    width: 250px;
  }
`;

const PrimaryButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const CheckoutButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 30px;
  @media (max-width: 600px) {
    font-size: 26px;
    margin-bottom: 0rem;
  }
`;

const SecondaryButton = styled.button`
  border: none;
  height: 52px;
  padding: 10px 20px;
  background: #d5d4c3;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const SecondaryButtonText = styled.div`
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 20px;
  color: #222;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const CollapseContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3rem;
`;

const CollapseButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  gap: 6px;
  padding: 6px;
  &:hover {
    background-color: #d5d4c3;
  }
`;

const CollapseText = styled.div`
  font-family: 'Poppins', sans-serif;
  color: #222;
  font-size: 20px;
`;


const Cart = ({isOpen, onClose}) => {
  const [total, setTotal] = useState(0);
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
  const { items, setItems } = useContext(ItemsContext);
  const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
  const { continuedWithoutSavingDoodlebugs, setContinuedWithoutSavingDoodlebugs } = useContext(ContinuedWithoutSavingDoodlebugsContext)
  const navigate = useNavigate();

  // scroll to the top
  useEffect(() => {
      window.scrollTo(0,0);
  }, []);

  const updateWindowWidth = () => {
    setWindowWidth(document.documentElement.clientWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);

    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    }
  }, []);

  const handleRemove = async (theLoserItemWeAreDumping) => {
    const newCart = items.filter(item => !(item.key === theLoserItemWeAreDumping.key));
    setItems(newCart);
  };

  const handleDecrement = async (itemKey) => {
    const changedItemIndex = items.findIndex(item => (item.key === itemKey));
    if (changedItemIndex !== -1 && items[changedItemIndex].quantity > 1) {
      const newCart = items.map((item, index) => 
        index === changedItemIndex ? {...item, quantity: item.quantity - 1} : item
      );
      setItems(newCart);
    }
  };

  const handleIncrement = async (itemKey) => {
    const changedItemIndex = items.findIndex(item => (item.key === itemKey));
    if (changedItemIndex !== -1 && items[changedItemIndex].quantity < 99) {
      const newCart = items.map((item, index) => 
        index === changedItemIndex ? {...item, quantity: item.quantity + 1} : item
      );
      setItems(newCart);
    }
  };

  useEffect(() => {
    const newTotal = 36 * items.reduce((acc, item) => {return acc + item.quantity}, 0);
    setTotal(newTotal);
  }, [items])

  const goToOrdering = () => {
    onClose();
    navigate('/order/phone-case')
  };

  const goToCustomize = () => {
    onClose();
    navigate('/new-doodlebug');
  }

  const checkout = async () => {
    const checkoutUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/checkout' : 'http://localhost:3001/checkout';
    try {
        const response = await fetch(checkoutUrl, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              'CSRF-Token': window.csrfToken
            },
            body: JSON.stringify({ items: items })
        })
        .then(res => res.json())
        .then(data => {
          window.location.href = data.stripeUrl;
        })

    } catch {
        console.error('server error :( de chekout canot be happen');
    }
  }

  return (
    <>
      {isOpen && (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'rgba(0, 0, 0, 0.3)',
                  backdropFilter: 'blur(3px)',
                  zIndex: 1100,
                }}
                onClick={onClose}
            />
      )}
      <motion.div
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? 0 : '100%' }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          width: '400px',
          maxWidth: '95%',
          height: '100%',
          boxShadow: isOpen ? '-2px 0 5px rgba(0,0,0,0.2)' : 'none',
          borderTopLeftRadius: '18px',
          borderBottomLeftRadius: '18px',
          zIndex: 1200,
        }}
      >
        <Background>
          <CartContainer>
            <CollapseContainer>
                <CollapseButton onClick={onClose}>
                    <CollapseText>Close</CollapseText>
                    <CaretRight size="26" color="#222222"/>
                </CollapseButton>
            </CollapseContainer>
            {(items.length < 1 || items[0] === "getting") && (
              <EmptyText>Your cart is empty!</EmptyText>
            )}
            <CartItemsContainer>
              {(items[0] !== "getting") && items.map(item => (
                <CartItem key={item.key} cartColor={item.cartColor}>
                  <ProductImageContainer>
                    <ProductImage src={localStorage.getItem(`${item.key}`)} />
                  </ProductImageContainer>
                  <ItemDetails>
                    <ItemName>{item.size} Case</ItemName>
                    <QuantityBox>
                      <ItemQuantity>QTY: <strong>{item.quantity}</strong></ItemQuantity>
                      <QuantityButtonsBox>
                        <DecrementButton onClick={() => handleDecrement(item.key)}><span>–</span></DecrementButton>
                        <IncrementButton onClick={() => handleIncrement(item.key)}><span>+</span></IncrementButton>
                      </QuantityButtonsBox>
                    </QuantityBox>
                    <ItemPrice>${36 * item.quantity}</ItemPrice>
                  </ItemDetails>
                  <IconWrapper>
                    <Trash onClick={() => handleRemove(item)} size={24}></Trash>
                  </IconWrapper>
                </CartItem>
              ))}
            </CartItemsContainer>
            <CartBottom>
                {(items.length < 1 && savedDoodlebugs.length > 0) || (items.length < 1 && continuedWithoutSavingDoodlebugs.length > 0) ? (
                  <ButtonContainer>
                    <PrimaryButton onClick={goToCustomize}><PrimaryButtonText>Create Doodlebug</PrimaryButtonText></PrimaryButton>
                    <SecondaryButton onClick={goToOrdering}><SecondaryButtonText>Order Something</SecondaryButtonText></SecondaryButton>
                  </ButtonContainer>
                ) : (items.length < 1) || (items[0] === "getting") ? (
                  <ButtonContainer>
                    <PrimaryButton onClick={goToCustomize}><PrimaryButtonText>Create Doodlebug</PrimaryButtonText></PrimaryButton>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <PrimaryButton onClick={checkout}><CheckoutButtonText>Checkout: ${36 * items.reduce((acc, item) => {return acc + item.quantity}, 0)}</CheckoutButtonText></PrimaryButton>
                    <SecondaryButton onClick={goToOrdering}><SecondaryButtonText>Add Something</SecondaryButtonText></SecondaryButton>
                  </ButtonContainer>
                )}
            </CartBottom>
          </CartContainer>
        </Background>
    </motion.div>
    </>
  );
};

export default Cart;
