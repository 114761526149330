import React, { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Rating from 'react-rating';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Images, X } from '@phosphor-icons/react';
import iphone16 from '../img/mockups/iphone_16.webp';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`;

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

const Background = styled.div`
    width: 100%;
    background: #fff9ef;
`;

const BigContainer = styled.div`
    padding-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
    padding-bottom: 10rem;
`;

const TitleText = styled.h1`
    font-family: 'NewKansas';
    font-size: 50px;
    margin-bottom: 3.5rem;
    margin-top: 1rem;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const ContainerForPictureAndRating = styled.div`
    display: flex;
    gap: 1rem;
`;

const ContainerForProductImage = styled.div`
    border: 4px solid #d5d4c3;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 112px;
    width: calc(112px * (1748/2480));
`;

const ProductImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const ContainerForRatingElements = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
    font-family: 'NewKansas';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 2rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const LabelNoUpperMargin = styled.div`
    font-family: 'NewKansas';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`

const EmailInput = styled.input`
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    height: 35px;
    border-radius: 10px;
    border: ${({ error }) => (error ? '4px solid #F71735' : '4px solid #d5d4c3')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    height: 46px;
    @media (max-width: 600px) {
        max-width: 600px;
    }
`;

const WideInput = styled.input`
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width: 100%;
    height: 35px;
    border-radius: 10px;
    border: ${({ error }) => (error ? '4px solid #F71735' : '4px solid #d5d4c3')};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    height: 46px;
    @media (max-width: 600px) {
        max-width: 600px;
    }
`;

const TextArea = styled.textarea`
  border: ${({ error }) => (error ? '4px solid #F71735' : '4px solid #d5d4c3')};
  resize: none;
  width: 100%;
  height: 12rem;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 3;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const MediaButton = styled.button`
  border: none;
  border-radius: 18px;
  height: 64px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 0px;
  background: #d5d4c3;
  box-sizing: border-box;
  color: #222;
  transform-origin: center;
  transition: transform 0.05s ease-in-out;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
  }
`;

const MediaButtonText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-weight: bolder;
  text-align: center;
  padding-left: 10px;
  font-size: 18px;
  color: #222;
  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 0rem;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiniImageGroup = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 80px);
    gap: 16px;
    margin-top: 1.5rem;
    margin-bottom: 0px;
    width: 100%;
`;

const MiniImageContainer = styled.div`
    border: 4px solid #d5d4c3;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    position: relative;
`;

// to prevent overflow of image, but allow for overflow of delete button
const MiniImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
`;

const DeleteUploadButton = styled.button`
    position: absolute;
    top: -12px;
    right: -12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #222;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    z-index: 100;
    > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.1s ease-in-out;
    }
    &:hover {
        transform: scale(1.1);
        transition: transform 0.1s ease-in-out;
        > svg {
            transform: scale(1.1);
            transition: transform 0.1s ease-in-out;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: transform 0.1s ease-in-out;
        }
    }
    @media (max-width: 600px) {
        &:hover {
            transform: scale(1);
            > svg {
                transform: scale(1);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: transform 0.1s ease-in-out;
            }
        }
    }
`;

const Button = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 3rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const ErrorText = styled.div`
  color: #F71735;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-top: 4px;
  display: ${({ display }) => (display ? 'block' : 'none')};
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: #F71735;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const StillUploadingPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: #ffe3bd;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const BlackPopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
  color: #222222;
  text-align: center;
`;

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ImWorkingHereButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 260px;
  height: 64px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #fff;
  background: #fe814d;
  border: none;
  border-radius: 18px;
  box-sizing: border-box;
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
`;

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const Divider = styled.hr`
  border: none;
  border-top: 3px solid #d5d4c3;
  border-radius: 1px;
  margin: 3rem 0px 3rem 0px;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 24px;
    margin-bottom: 4rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const UploadOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
`;

const ProgressCircle = styled.svg`
    transform: rotate(-90deg);
    width: 40px;
    height: 40px;
    z-index: 1000;
`;

const ProgressCirclePath = styled.circle`
    fill: none;
    stroke: #fff;
    stroke-width: 4;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.1s ease;
`;


const NewReview = () => {
    const [reviewTitle, setReviewTitle] = useState('');
    const [reviewContent, setReviewContent] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [reviewScore, setReviewScore] = useState(0);
    const [reviewIsValid, setReviewIsValid] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);
    const [reviewSource, setReviewSource] = useState('website');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showStillUploadingPopup, setShowStillUploadingPopup] = useState(false);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);
    const [imWorkingHere, setImWorkingHere] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [showingWarnings, setShowingWarnings] = useState(false);
    const [showRatingError, setShowRatingError] = useState(false);
    const [showTitleError, setShowTitleError] = useState(false);
    const [showContentError, setShowContentError] = useState(false);
    const [showNameError, setShowNameError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const fileInputRef = useRef();
    const titleRef = useRef();
    const contentRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();

    useEffect(() => {
        document.title = 'Doodlebug | New Review'
    }, []);

    useEffect(() => {
        if (reviewTitle.trim() !== '' && reviewContent.trim() !== '' && name.trim() !== '' && validEmail && reviewScore > 0) {
            setReviewIsValid(true);
        } else {
            setReviewIsValid(false);
        }
    }, [reviewTitle, reviewContent, email, validEmail, reviewScore, name]);

    const updateReviewTitle = (e) => {
        setReviewTitle(e.target.value);
        if(showingWarnings && e.target.value.trim() === '') {
            setShowTitleError(true);
        } else if (showingWarnings) {
            setShowTitleError(false);
        }
    }

    const updateReviewContent = (e) => {
        setReviewContent(e.target.value);
        if(showingWarnings && e.target.value.trim() === '') {
            setShowContentError(true);
        } else if (showingWarnings) {
            setShowContentError(false);
        }
    }

    const updateName = (e) => {
        setName(e.target.value);
        if(showingWarnings && e.target.value.trim() ===  '') {
            setShowNameError(true);
        } else if (showingWarnings) {
            setShowNameError(false);
        }
    }

    const updateEmail = (e) => {
        setEmail(e.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if(showingWarnings && re.test(String(e.target.value).toLowerCase())) {
            setShowEmailError(false);
        } else if (showingWarnings) {
            setShowEmailError(true);
        }
        
        if (re.test(String(e.target.value).toLowerCase())) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }

    const handleUploadPhotos = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = async (e) => {
        const files = Array.from(e.target.files);
        const validFiles = files.filter(file => {
            const isImage = file.type.startsWith('image/');
            const isUnderLimit = file.size <= 10 * 1024 * 1024; // 10MB limit
            return isImage && isUnderLimit;
        });
    
        if (uploads.length + validFiles.length > 4) {
            validFiles.splice(4 - uploads.length);
        };
    
        const compressionOptions = {
            maxSizeMB: 0.8,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            onProgress: (progress) => {
                console.log('Compression progress:', progress);
            },
        };
    
        const newUploads = validFiles.map(file => ({
            id: uuidv4(),
            file,
            status: 'uploading',
            progress: 0,
            url: null
        }));
    
        setUploads(prev => [...prev, ...newUploads]);
    
        for (const upload of newUploads) {
            // Start simulated compression progress
            const simulateCompressionProgress = () => {
                let progress = 0;
                const interval = setInterval(() => {
                    progress += 1;
                    if (progress <= 20) {
                        setUploads(prev => prev.map(u => 
                            u.id === upload.id 
                                ? { ...u, progress }
                                : u
                        ));
                    } else {
                        clearInterval(interval);
                    }
                }, 50); // Increment every 50ms
                return interval;
            };
    
            const compressionInterval = simulateCompressionProgress();
    
            try {
                console.log('Starting compression for file:', upload.file.size / 1024 / 1024, 'MB');
                console.log('Web Worker enabled:', compressionOptions.useWebWorker);
                const startTime = performance.now();

                const compressedFile = await imageCompression(upload.file, compressionOptions);

                const endTime = performance.now();
                console.log('Compression took:', (endTime - startTime) / 1000, 'seconds');
                console.log('Compressed size:', compressedFile.size / 1024 / 1024, 'MB');

                clearInterval(compressionInterval); // Stop simulated progress
                
                // Set progress to 20 in case it didn't reach it during compression
                setUploads(prev => prev.map(u => 
                    u.id === upload.id 
                        ? { ...u, progress: 20 }
                        : u
                ));
    
                const url = await uploadImage(compressedFile);
                setUploads(prev => prev.map(u => 
                    u.id === upload.id 
                        ? { ...u, status: 'complete', url, progress: 100 }
                        : u
                ));
            } catch (error) {
                clearInterval(compressionInterval);
                setUploads(prev => prev.map(u => 
                    u.id === upload.id 
                        ? { ...u, status: 'error', progress: 0 }
                        : u
                ));
            }
        }
    };

    const uploadImage = async (file) => {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();
            formData.append('photo', file);
    
            const uploadUrl = process.env.REACT_APP_ENVIRONMENT === 'production' 
                ? 'https://api.doodlebug.me/upload-review-photo' 
                : 'http://localhost:3001/upload-review-photo';
    
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setUploads(prev => prev.map(u => 
                        u.file === file 
                            ? { ...u, progress }
                            : u
                    ));
                }
            });
    
            xhr.addEventListener('load', () => {
                if (xhr.status === 200) {
                    const response = JSON.parse(xhr.responseText);
                    resolve(response.url);
                } else {
                    reject(new Error('Upload failed'));
                }
            });
    
            xhr.addEventListener('error', () => {
                reject(new Error('Upload failed'));
            });
    
            xhr.withCredentials = true;
            xhr.open('POST', uploadUrl);
            xhr.send(formData);
        });
    };

    const handleDeleteImage = (idToDelete) => {
        setUploads(uploads.filter(upload => upload.id !== idToDelete));
    };

    const submitReview = async () => {
        if (reviewIsValid) { 
            setImWorkingHere(true);

            // Check if uploads are still in progress
            const uploadsInProgress = uploads.some(upload => upload.status === 'uploading');

            if (uploadsInProgress) {
                const completedUploads = uploads.filter(upload => upload.status === 'complete').length;

                setShowStillUploadingPopup({ show: true, completed: completedUploads, total: uploads.length });
                
                // Wait for all uploads to complete
                await new Promise(resolve => {
                    const checkUploads = setInterval(() => {
                        setUploads(currentUploads => {
                            const stillUploading = currentUploads.some(upload => upload.status === 'uploading');
                            
                            if (!stillUploading) {
                                clearInterval(checkUploads);
                                setShowStillUploadingPopup({ show: false, completed: 0, total: 0 });
                                resolve();
                            } else {
                                const completed = currentUploads.filter(upload => upload.status === 'complete').length;
                                setShowStillUploadingPopup(prev => ({ ...prev, completed }));
                            }
                            return currentUploads;
                        });
                    }, 100);
                });
            }

            try {
                const formData = new FormData();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('review_content', reviewContent);
                formData.append('review_score', reviewScore);
                formData.append('review_title', reviewTitle);
                formData.append('review_source', reviewSource);
                formData.append('product_title', 'Doodlebug Phone Case');

                const uploadedUrls = uploads
                    .filter(upload => upload.status === 'complete')
                    .map(upload => upload.url);
                formData.append('photoUrls', JSON.stringify(uploadedUrls));

                const postUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/add-review' : 'http://localhost:3001/add-review';
                const response = await fetch(postUrl, {
                    method: 'POST',
                    credentials: 'include',
                    body: formData
                });

                if (!response.ok) {
                    throw new Error('Netowrk response was not ok :(');
                }
                
                setPostSuccess(true);
            } catch (err) {

            } finally {
                setImWorkingHere(false);
            }
        } else {
            setShowingWarnings(true);

            const hasRatingError = !reviewScore || reviewScore < 1;
            const hasTitleError = reviewTitle.trim() === '';
            const hasContentError = reviewContent.trim() === '';
            const hasNameError = name.trim() === '';
            const hasEmailError = !validEmail;
    
            setShowRatingError(hasRatingError);
            setShowTitleError(hasTitleError);
            setShowContentError(hasContentError);
            setShowNameError(hasNameError);
            setShowEmailError(hasEmailError);

            setShowInvalidPopup(true);
            setTimeout(() => {
                setShowInvalidPopup(false);
            }, 3000);
    
            if (hasRatingError) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            } else if (hasTitleError) {
                titleRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (hasContentError) {
                contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (hasNameError) {
                nameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (hasEmailError) {
                emailRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            return;
        }
    };

    return (
        <>
            <Background>
                <BigContainer>
                    {postSuccess? (
                        <>
                        <TitleText>Thank you!</TitleText>
                        <Message>Doodlebug has received your review. It may take up for 24 hours for it to be posted. You can close this tab now.</Message>
                        </>
                    ) : (
                    <>
                    <TitleText>Doodlebug</TitleText>
                    <ContainerForPictureAndRating>
                        <ContainerForProductImage>
                            <ProductImage src={iphone16} />
                        </ContainerForProductImage>
                        <ContainerForRatingElements>
                            <LabelNoUpperMargin>Doodlebug Phone Case</LabelNoUpperMargin>
                            <Rating
                                emptySymbol={<FontAwesomeIcon icon={regularStar} className="fa-2x" style={{ color: "#fdca40", fontSize: "36px"}}/>}
                                fullSymbol={<FontAwesomeIcon icon={solidStar} className="fa-2x" style={{ color: "#fdca40", fontSize: "36px"}}/>}
                                style={{ marginBottom: '4px' }}
                                initialRating={reviewScore}
                                onChange={value => {
                                    setReviewScore(value);
                                    if (showingWarnings && ![1,2,3,4,5].includes(value)) {
                                        setShowRatingError(true);
                                    } else if (showingWarnings) {
                                        setShowRatingError(false);
                                    }
                                }}
                            />
                            <ErrorText display={showRatingError}>Please enter a rating</ErrorText>
                        </ContainerForRatingElements>
                    </ContainerForPictureAndRating>
                    <Label ref={titleRef}>Review Title</Label>
                    <WideInput value={reviewTitle} onChange={updateReviewTitle} placeholder="Your review title" error={showTitleError}></WideInput>
                    <ErrorText display={showTitleError}>Please enter a title</ErrorText>
                    <Label ref={contentRef}>Review Description</Label>
                    <TextArea value={reviewContent} onChange={updateReviewContent} placeholder="Your message" error={showContentError}></TextArea>
                    <ErrorText display={showContentError}>Please enter your thoughts about your Doodlebug Phone Case</ErrorText>
                    <input 
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        multiple
                        accept="image/*"
                        onChange={handleFileSelect}
                    />
                    <MediaButton onClick={handleUploadPhotos}>
                        <ButtonContent>
                            <Images size={24} color='#222' /><MediaButtonText>Add Photos ({uploads.length}/4)</MediaButtonText>
                        </ButtonContent>
                    </MediaButton>
                    {uploads.length > 0 && (
                        <MiniImageGroup>
                            {uploads.map((upload) => (
                                <MiniImageContainer key={upload.id}>
                                    <DeleteUploadButton onClick={() => handleDeleteImage(upload.id)}>
                                        <X size={16} color="#fff" weight="bold" />
                                    </DeleteUploadButton>
                                    <MiniImageWrapper>
                                        <ProductImage src={URL.createObjectURL(upload.file)} />
                                        {upload.status === 'uploading' && (
                                            <UploadOverlay>
                                                <ProgressCircle viewBox="0 0 36 36">
                                                    <ProgressCirclePath
                                                        cx="18"
                                                        cy="18"
                                                        r="16"
                                                        d="M18 2.0845
                                                            a 15.9155 15.9155 0 0 1 0 31.831
                                                            a 15.9155 15.9155 0 0 1 0 -31.831"
                                                        strokeDasharray="100, 100"
                                                        strokeDashoffset={100 - upload.progress}
                                                    />
                                                </ProgressCircle>
                                            </UploadOverlay>
                                        )}
                                    </MiniImageWrapper>
                                </MiniImageContainer>
                            ))}
                        </MiniImageGroup>   
                    )}
                    <Divider />
                    <Label ref={nameRef}>Name</Label>
                    <EmailInput value={name} onChange={updateName} placeholder="Mary Doodle" error={showNameError}></EmailInput>
                    <ErrorText display={showNameError}>Please enter your name</ErrorText>
                    <Label ref={emailRef}>Email</Label>
                    <EmailInput value={email} onChange={updateEmail} placeholder="email@example.com" error={showEmailError}></EmailInput>
                    <ErrorText display={showEmailError}>Please enter a valid email</ErrorText>
                    {imWorkingHere ? (
                        <ImWorkingHereButton><Dot/><Dot/><Dot/></ImWorkingHereButton>
                    ) : (
                        <Button onClick={submitReview} isValid={reviewIsValid}><ButtonText>Submit</ButtonText></Button>
                    )}
                    {showErrorPopup && (
                        <ErrorPopup><PopupText>Something went wrong. If this issue persists, you can reach us directly at support@doodlebug.me.</PopupText></ErrorPopup>
                    )}
                    {showInvalidPopup && (
                        <ErrorPopup><PopupText>Please fill in all required fields to submit a review.</PopupText></ErrorPopup>
                    )}
                    {showStillUploadingPopup && (
                        <StillUploadingPopup><BlackPopupText>Uploading {showStillUploadingPopup.completed}/{showStillUploadingPopup.total} images...</BlackPopupText></StillUploadingPopup>
                    )}
                </>
                )}
                </BigContainer>
            </Background>
        </>
    )
}

export default NewReview;
