import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import UserInfoContext from '../contexts/UserInfoContext';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import Select from 'react-select';
import { CaretLeft, CaretRight, CheckFat, Plus, Star, X } from '@phosphor-icons/react';
import '../css/fonts.css';

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

const Background = styled.div`
  width: 100%;
  background: #fff9ef;
`;

const Container = styled.div`
  padding-top: 2.5rem;
  width: 62%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
      width: 85%;
  }
  padding-bottom: 10rem;
`;

const Label = styled.div`
  font-family: 'NewKansas';
  font-size: 36px;
  font-weight: bold;
  display: block;
  color: #222222;
`;

const Paragraph = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #222222;
`;

const Button = styled.button`
  width: 230px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  margin-right: auto;
  margin-left: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 22px;
    margin-bottom: 0rem;
  }
`;

const OrderCard = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 18px;
  background: #ffe3bd;
  padding: 1rem;
  margin-bottom: 2rem;
`;

const InfoTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
`;

const LilInfoTag = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 38px;
  gap: 10px;
  margin-bottom: .8rem;
  width: auto;
`;

const LilInfoText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #222;
`;

const QuantityText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #666666;
  margin-bottom: 1rem;
`;

const DoodlebugOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  width: 100%;
  gap: 1rem;
`;

const NewSelect1 = styled(Select)`
  box-sizing: border-box;
  width: 8.1rem;
  max-width: 8.1rem;
  margin-right: 0;
  color: #222;
  @media (max-width: 400px) {

  }
  border: 4px solid '#d5d4c3 ';
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NewDoodlebugButton = styled.button`
  border: none;
  border-radius: 8px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0px;
  background: #d5d4c3;
  box-sizing: border-box;
  color: #222;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: scale(.99);
    transition: all 0.05s ease-in-out;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewDoodlebugButtonText = styled.div`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  padding-left: 6px;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  gap: 10px;
  margin-bottom: 1.5rem;
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 4px solid #d5d4c3;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.03);
  }
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  aspect-ratio: 904 / 1032;
`;

const GridImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const FocusedImageContainer = styled(motion.div)`
  position: relative;
  max-width: 90%;
  max-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    max-width: 80%;
  };
  @media (max-width: 600px) {
    max-width: 70%;
  };
`;

const FocusedImage = styled.img`
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
  border-radius: 18px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${props => props.direction === 'left' ? 'left: -50px;' : 'right: -50px;'}
  
  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const DeleteDialogContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2000;
`;


const MyDoodlebugs = () => {
    const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const navigate = useNavigate();
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [focusedDoodlebugIndex, setFocusedDoodlebugIndex] = useState(null);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const doodlebugSortOptions = [
      { value: 'Newest', label: 'Newest' },
      { value: 'Oldest', label: 'Oldest' }
    ];
    const [doodlebugSort, setDoodlebugSort] = useState('Newest');

    const selectStyles = {
      control: (base) => ({
        ...base,
        border: '4px solid #d5d4c3',
        borderRadius: '8px',
        boxShadow: 'none',
        boxSizing: 'border-box',
        height: '50px',
        appearance: 'none',
        backgroundColor: '#fff',
        textAlign: 'center',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        paddingLeft: '18px'
      }),
      option: (base) => ({
        ...base,
        color: '#222',
        fontFamily: "'Poppins', sans-serif",
        fontSize: window.innerWidth < 600 ? '14px' : '16px'
      }),
      placeholder: (base) => ({
        ...base,
        color: '#222',
        fontFamily: "'Poppins', sans-serif",
        fontSize: window.innerWidth < 600 ? '14px' : '16px'
      })
    };

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
      document.title = 'Doodlebug | My Doodlebugs';
    }, []);

    // listen for keyboard inputs, so that user can scroll between doodlebugs in the focused state
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (focusedDoodlebugIndex === null) return;
            if (e.key === 'ArrowLeft') navigateDoodlebugs(-1);
            if (e.key === 'ArrowRight') navigateDoodlebugs(1);
            if (e.key === 'Escape') closeFocusedDoodlebug();
        }

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [focusedDoodlebugIndex]);

    const navigateToOrdering = () => {
        navigate('/order/phone-case');
    };

    const navigateToCustomize = () => {
        navigate('/new-doodlebug')
    };

    const handleDoodlebugSortChange = (selectedOption) => {
      setDoodlebugSort(selectedOption.value);
    };

    const getSortedDoodlebugs = () => {
      const sortedDoodlebugs = [...savedDoodlebugs];
      if (doodlebugSort === 'Newest') {
        return sortedDoodlebugs.reverse();
      }
      return sortedDoodlebugs;
    };

    const focusDoodlebug = (index) => {
        setFocusedDoodlebugIndex(index);
    };

    const closeFocusedDoodlebug = () => {
        setFocusedDoodlebugIndex(null);
    };

    const navigateDoodlebugs = (direction) => {
        if (focusedDoodlebugIndex === null) return;
        const newIndex = (focusedDoodlebugIndex + direction + savedDoodlebugs.length) % savedDoodlebugs.length;
        setFocusedDoodlebugIndex(newIndex);
    };

    const openConfirmDeleteDialog = () => {
        setShowConfirmDeleteDialog(true);
    };

    const closeConfirmDeleteDialog = () => {
        setShowConfirmDeleteDialog(false);
    }

    const openLogin = () => {

    };

    const deleteDoodlebug = async () => {
        const originalSavedDoodlebugs = savedDoodlebugs.slice();
        if (focusedDoodlebugIndex !== null && focusedDoodlebugIndex >= 0) {
            const newSavedDoodlebugs = savedDoodlebugs.filter(
                (bug, index) => index !== focusedDoodlebugIndex
            );
            // optimistically update
            setSavedDoodlebugs(newSavedDoodlebugs);
        } else {
            return;
        };

        try {
            const deleteUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/deletedoodlebugs' : 'http://localhost:3001/api/deletedoodlebugs';
            const response = await fetch(deleteUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              body: JSON.stringify({ doodlebugsToDelete: savedDoodlebugs[focusedDoodlebugIndex] }),
              credentials: 'include'
            });
            const data = await response.json();
    
            if (response.ok) {
              console.log()
            } else if (data.triggerLogin) {
              openLogin();
            } else {
              setSavedDoodlebugs(originalSavedDoodlebugs);
              throw new Error(data.message || 'Error saving doodlebugs');
            }
        } catch (error) {
          console.error('Save failed', error);
          setSavedDoodlebugs(originalSavedDoodlebugs);
        }
    };

    return (
        <motion.div layout>
        <Background>
          <Container>
              <OrderCard>
                <InfoTagsContainer>
                <LilInfoTag>
                  <CheckFat size={window.innerWidth > 800 ? 20 : 16} color="#222" weight='fill' />
                  <LilInfoText>Free shipping on all orders</LilInfoText>
                </LilInfoTag>
                <LilInfoTag>
                  <CheckFat size={window.innerWidth > 800 ? 20 : 16} color="#222" weight='fill' />
                  <LilInfoText>Arrives within 5-8 days of order</LilInfoText>
                </LilInfoTag>
                <LilInfoTag>
                  <CheckFat size={window.innerWidth > 800 ? 20 : 16} color="#222222" weight='fill' />
                  <LilInfoText>Works with MagSafe</LilInfoText>
                </LilInfoTag>
                <LilInfoTag>
                  <CheckFat size={window.innerWidth > 800 ? 20 : 16} color="#222222" weight='fill' />
                  <LilInfoText>No extra tax at checkout</LilInfoText>
                </LilInfoTag>
                </InfoTagsContainer>
                <Button onClick={navigateToOrdering}><ButtonText>Order Now</ButtonText></Button>
              </OrderCard>
              <Label>My Doodlebugs</Label>
              <QuantityText>Showing {savedDoodlebugs.length} doodlebug{savedDoodlebugs.length !== 1 ? 's' : ''}</QuantityText>
              <DoodlebugOptionsContainer>
                <NewSelect1
                  options={doodlebugSortOptions}
                  styles={selectStyles}
                  placeholder={doodlebugSort}
                  value={null}
                  isSearchable={false}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  onChange={handleDoodlebugSortChange}
                />
                <NewDoodlebugButton onClick={navigateToCustomize}>
                  <ButtonContent>
                    <Plus size={18} color='#222' />
                    <NewDoodlebugButtonText>New Doodlebug</NewDoodlebugButtonText>
                  </ButtonContent>
                </NewDoodlebugButton>
              </DoodlebugOptionsContainer>
              <StyledGrid>
                {getSortedDoodlebugs().map((url, index) => (
                  <motion.div layout key={url}>
                    <ImageContainer onClick={() => focusDoodlebug(index)}>
                      <GridImage src={url} alt="Doodlebug" />
                    </ImageContainer>
                  </motion.div>
                ))}
              </StyledGrid>
          </Container>
          <AnimatePresence>
                {focusedDoodlebugIndex !== null && (
                    <Overlay
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        onClick={closeFocusedDoodlebug}
                    >
                        <FocusedImageContainer
                            onClick={(e) => e.stopPropagation()}
                            initial={{ scale: 0.8, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.8, opacity: 0 }}
                        >
                            <>
                                <FocusedImage
                                    src={getSortedDoodlebugs()[focusedDoodlebugIndex]}
                                    alt="Doodlebug"
                                    drag="x"
                                    dragConstraints={{ left: 0, right: 0 }}
                                    dragElastic = {1}
                                    onDragEnd={(e, {offset, velocity}) => {
                                        if (offset.x < -50) navigateDoodlebugs(1);
                                        if (offset.x > 50) navigateDoodlebugs(-1);
                                    }}
                                />
                                {savedDoodlebugs.length > 1 && (
                                  <>
                                    <ArrowButton direction="left" onClick={() => navigateDoodlebugs(-1)}>
                                        <CaretLeft size={24} color="#fff" weight="bold"/>
                                    </ArrowButton>
                                    <ArrowButton direction="right" onClick={() => navigateDoodlebugs(1)}>
                                        <CaretRight size={24} color="#fff" weight="bold"/>
                                    </ArrowButton> 
                                  </>
                                )}
                            </>
                            <CloseButton onClick={closeFocusedDoodlebug}>
                                <X size={24} color="#ffffff" weight="bold"/>
                            </CloseButton>
                        </FocusedImageContainer>
                    </Overlay>
                )}
            </AnimatePresence>
            {showConfirmDeleteDialog && (
                <Overlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={closeConfirmDeleteDialog}
                >
                  <DeleteDialogContainer
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Label>Are you sure you want to delete? You cannot undo this action.</Label>
                    <Button>Yes, delete</Button>
                    <CloseButton onClick={closeConfirmDeleteDialog}>
                        <X size={24} />
                    </CloseButton>
                  </DeleteDialogContainer>
                </Overlay>
              )}
        </Background>
        </motion.div>
    );
};

export default MyDoodlebugs;
