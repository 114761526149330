import CheckoutSuccess from "./pages/CheckoutSuccess";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NewReview from "./pages/NewReview";
import Contact from "./pages/Contact";
import Terms from "./pages/Terms";
import FAQ from "./pages/FAQ";
import About from "./pages/About";
import Partners from "./pages/Partners";
import Landing from "./pages/Landing";
import MyDoodlebugs from "./pages/MyDoodlebugs";
import Customize from "./pages/Customize";
import OrderPhoneCase from "./pages/OrderPhoneCase";
import John316 from "./pages/John316";
import Join from "./pages/Join";
import Quiz from "./pages/Quiz";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
import GetUserState from "./components/GetUserState";
import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SavedDoodlebugsContext from "./contexts/SavedDoodlebugsContext";
import ContinuedWithoutSavingDoodlebugsContext from "./contexts/ContinuedWithoutSavingDoodlebugsContext";
import ItemsContext from "./contexts/ItemsContext";
import UserInfoContext from "./contexts/UserInfoContext";
import '@fortawesome/fontawesome-svg-core/styles.css';
import Cookies from 'js-cookie';
import { motion, LayoutGroup } from 'framer-motion';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

const AllContainer = styled.div`
  ${props => props.isScrollDisabled && `
  overflow: hidden;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
  `};
  background: #fff9ef;
`;

const MainContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 30px);
  overflow-y: hidden;
  overflow-x: hidden;
`;

const GrowContainer = styled(motion.div)`
  flex: 1;
  background: #fff9ef;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
`;


const App = () => {
  async function initializeCSRF() {
    const CSRF_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/get-csrf-token' : 'http://localhost:3001/get-csrf-token';
    try {
      const response = await fetch(CSRF_URL, {
        credentials: 'include'
      });
      const data = await response.json();
      window.csrfToken = data.csrfToken;
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
    }
  };

  useEffect(() => {
    initializeCSRF();
  }, []);

  const [savedDoodlebugs, setSavedDoodlebugs] = React.useState([]);
  const [continuedWithoutSavingDoodlebugs, setContinuedWithoutSavingDoodlebugs] = React.useState([]);
  const [items, setItems] = React.useState(["getting"]);
  const [userInfo, setUserInfo] = React.useState({status: "getting"});
  const [isCartOpen, setIsCartOpen] = React.useState(false);

  const openCart = () => {
    setIsCartOpen(true);
  };

  const closeCart = () => {
    setIsCartOpen(false);
  };

  useEffect(() => {
    // console.log('Doodlebug.me: We certify that this work was completed with all diligence and faithfulness in the service of God, the Father of all lights, with great love and profound admiration for the remarkable human family.')
  }, []);

  useEffect(() => {
    if (items.length === 0 && Cookies.get('items')) {
      setItems(JSON.parse(Cookies.get('items')));
    }
    if (continuedWithoutSavingDoodlebugs.length === 0 && Cookies.get('continuedWithoutSavingDoodlebugs')) {
      setContinuedWithoutSavingDoodlebugs(JSON.parse(Cookies.get('continuedWithoutSavingDoodlebugs')));
    }
  }, []);
  
  // keep the server cart updated
  const updateServerCart = useCallback(
    debounce(async (newCart) => {
      const CART_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/update-cart' : 'http://localhost:3001/update-cart';

      try {
        const response = await fetch(CART_URL, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': window.csrfToken
          },
          body: JSON.stringify({ items: newCart }),
        });

        if (!response.ok) {
          throw new Error('Failed to update cart on server');
        }
      } catch (error) {
        console.error('Error updating cart on server:', error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if(items[0] !== "getting") {
      updateServerCart(items);
    }
  }, [items, updateServerCart]);

  return (
    <SavedDoodlebugsContext.Provider value = {{ savedDoodlebugs, setSavedDoodlebugs }}>
      <ContinuedWithoutSavingDoodlebugsContext.Provider value ={{ continuedWithoutSavingDoodlebugs, setContinuedWithoutSavingDoodlebugs }} >
        <ItemsContext.Provider value = {{ items, setItems }}>
          <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
            <AllContainer style={{background: '#fff9ef'}} isScrollDisabled={isCartOpen}>
              <GetUserState />
              <Router>
                <Navbar openCart={openCart}/>
                  <LayoutGroup>
                    <MainContainer layout>
                      <GrowContainer layout>
                        <motion.div layout>
                          <Routes>
                            <Route path="/checkoutsuccess" element={<CheckoutSuccess/>}/>
                            <Route path="privacy" element={<PrivacyPolicy/>}/>
                            <Route path="new-review" element={<NewReview/>}/>
                            <Route path="contact" element={<Contact/>}/>
                            <Route path="terms" element={<Terms/>}/>
                            <Route path="faq" element={<FAQ/>}/>
                            <Route path="about" element={<About/>}/>
                            <Route path="partners" element={<Partners/>}/>
                            <Route path="customize" element={<Customize/>}/>
                            <Route path="new-doodlebug" element={<Customize/>}/>
                            <Route path="order/phone-case" element={<OrderPhoneCase openCart={openCart}/>}/>
                            <Route path="john316" element={<John316/>}/>
                            <Route path="join" element={<Join/>}/>
                            <Route path="quiz" element={<Quiz/>}/>
                            <Route path="my-doodlebugs" element={<MyDoodlebugs/>}/>
                            <Route path="/" element={<Landing/>}/>
                          </Routes>
                        </motion.div>
                      </GrowContainer>
                      <motion.div layout>
                        <Footer />
                      </motion.div>
                    </MainContainer>
                  </LayoutGroup>
                <Cart isOpen={isCartOpen} onClose={closeCart} />
              </Router>
            </AllContainer>
          </UserInfoContext.Provider>
        </ItemsContext.Provider>
      </ContinuedWithoutSavingDoodlebugsContext.Provider>
    </SavedDoodlebugsContext.Provider>
  );
};

export default App;