import React from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { BagSimple, BookOpenText, ChatsCircle, FingerprintSimple, Info, ShootingStar } from '@phosphor-icons/react';


const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const TextFooterBottom = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #cccccc;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const ButtonFooterBottom = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #cccccc;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

const FooterBox = styled.footer`
  background: #222222;
  padding: 2rem;
  display: ${props => props.location === '/new-review' ? 'none' : 'flex'};
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3.5rem;
`;

const FooterTitle = styled.h3`
  font-family: 'NewKansas';
  font-size: 38px;
  margin-bottom: 1rem;
  color: #ddd;
  display: block;
`;

const FooterLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 10px;
`;

const FooterLink = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  text-decoration: none;
  margin-bottom: 10px;
  color: #ddd;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  padding: 0;
  &:hover {
    text-decoration: underline;
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 18px;
    &:hover {
      text-decoration: none;
      transition: all 0.05s ease-in-out;
    }
  }
  @media (max-width: 350px) {
    font-size: 16px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialLink = styled.a`
  margin: 0 0.5rem;
`;

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <FooterBox location={location.pathname}>
      <FooterTitle>Helpful Links</FooterTitle>
      <FooterLinkContainer>
        <FooterLink onClick={() => navigate('/contact')}><ChatsCircle size={24} color="#dddddd" weight="bold"/>Contact</FooterLink>
        <FooterLink onClick={() => navigate('/faq')}><Info size={24} color="#dddddd" weight="bold" />FAQ</FooterLink>
        <FooterLink onClick={() => navigate('/about')}><BookOpenText size={24} color="#dddddd" weight="bold" />About</FooterLink>
        <FooterLink onClick={() => navigate('/join')}><BagSimple size={24} color="#dddddd" weight="bold" />Join the Team</FooterLink>
        <FooterLink onClick={() => navigate('/partners')}><ShootingStar size={24} color='#dddddd' weight="bold"/>Influencers and Partners</FooterLink>
        <FooterLink onClick={() => navigate('/privacy')}><FingerprintSimple size={24} color="#dddddd" weight="bold" />Privacy Policy</FooterLink>
      </FooterLinkContainer>
      <FooterBottom>
        <TextFooterBottom>© The Doodlebug Company 2025</TextFooterBottom>
        <ButtonFooterBottom onClick={() => navigate('/terms')}>Terms of Service</ButtonFooterBottom>
      </FooterBottom>
      <SocialLinks>
        <SocialLink href="/instagram"></SocialLink>
        <SocialLink href="/tiktok"></SocialLink>
      </SocialLinks>
    </FooterBox>
  )

};

export default Footer;